import React from 'react';
import Helmet from 'react-helmet';
import config from '../../data/SiteConfig';
import Navigation from '../components/Navigation/Navigation';
import ThemeContext from '../context/ThemeContext';
import Footer from '../components/Footer/Footer';
import '../styles/main.scss';

export default class MainLayout extends React.Component {
  static contextType = ThemeContext; //eslint-disable-line

  render() {
    const { dark, notFound } = this.context;
    const { children } = this.props;
    let themeClass = '';

    if (dark && !notFound) {
      themeClass = 'dark';
    } else if (notFound) {
      themeClass = 'not-found';
    }

    return (
      <div>
        <Helmet
          bodyAttributes={{
            class: `theme ${themeClass}`,
          }}
        >
          <meta name="description" content={config.siteDescription} />
          <html lang="en" />
        </Helmet>
        <Navigation menuLinks={config.menuLinks} />
        <main id="main-content">{children}</main>
        <Footer />
      </div>
    );
  }
}
